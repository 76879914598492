class App {
    $deleteModal;
    $navigation;
    $page;
    $window;
    $wrapper;
    navHeight;
    page;
    region;

    load() {
        return new Promise((resolve, reject) => {
            document.onreadystatechange = () => {
                if (/loaded|complete/.test(document.readyState)) {
                    this.$deleteModal = $('#delete-element-modal');
                    this.$navigation = $('#site-navigation');
                    this.$window = $(window);
                    this.$wrapper = $('#main-wrapper');
                    this.$page = this.$wrapper.children('.content-wrapper').first();
                    this.page = 'index';
                    this.region = document.documentElement.lang;

                    if (this.$page[0]) {
                        this.page = this.$page[0].id.replace(new RegExp('-page$'), '');
                    }

                    if (this.$window.width() >= 1024) {
                        this.navHeight = this.$navigation.children('.navbar').outerHeight();
                    }

                    this.$window.resize($.throttle(() => {
                        if (this.$window.width() >= 1024) {
                            this.navHeight = this.$navigation.children('.navbar').outerHeight();
                        }
                    }, 150));

                    resolve();
                }
            };
        });
    }

    setPageHeight() {
        const height = this.$wrapper.outerHeight() - $('#footer').outerHeight();

        this.$page.attr('style', '');

        if (this.$page.outerHeight() < height) {
            this.$page.css({
                'height' : '1px',
                'min-height' : `${height}px`,
            });
        }
    }
}

export const app = new App();